<template>
  <ModuleHeader code="referential" />
  <v-tabs v-model="tab" color="primary">
    <v-tab value="datasets">
      {{ t('referential.dataset.label', 2) }}
    </v-tab>
    <v-tab value="classifiers">
      {{ t('referential.classifier.label', 2) }}
    </v-tab>
<!--    <v-tab value="providers">-->
<!--      {{ t('referential.provider.label', 2) }}-->
<!--    </v-tab>-->
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item value="datasets">
      <v-card class="mx-auto">
        <Datasets />
      </v-card>
    </v-window-item>
    <v-window-item value="classifiers">
      <v-card class="mx-auto">
        <Classifiers />
      </v-card>
    </v-window-item>
    <v-window-item value="providers">
      <v-card class="mx-auto">
        <Providers />
      </v-card>
    </v-window-item>
  </v-window>

</template>
<script setup>
import ModuleHeader from "@/components/common/ModuleHeader.vue";
import Datasets from "@/components/referential/Datasets.vue";
import Classifiers from "@/components/referential/Classifiers.vue";
import Providers from "@/components/referential/Providers.vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

let {t} = useI18n()
let tab = ref('datasets')
</script>