<template>
  <v-dialog v-model="dialog" @keydown.esc="cancel" persistent>
    <v-card width="600px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="ma-6">
        <v-row justify="center">
          {{ message }}
        </v-row>
        <v-row v-if="confirmation" class="mt-8" align="center">
          <span class="mr-2" v-html="t('common.confirmation-text', {text: confirmation})" />
          <v-text-field v-model:model-value="confirmationTyped" hide-details variant="outlined" density="compact" :error="confirmationError" bg-color="white"/>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="error" variant="text" @click.native="cancel">{{ t('common.cancel') }}</v-btn>
        <v-btn color="primary darken-1" variant="text" @click.native="agree">{{ t('common.yes')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>

import {ref} from "vue";
import {useI18n} from "vue-i18n";

let dialog = ref(false)
let resolve = ref(null)
let reject = ref(null)
let message = ref(null)
let title = ref(null)
let confirmation = ref(null)
let confirmationTyped = ref('')
let confirmationError = ref(false)
let options = ref({
  width: 290,
  zIndex: 200
})
let {t} = useI18n()

function open(title, message, confirmation = undefined) {
  dialog.value = true
  title.value = title
  message.value = message
  confirmation.value = confirmation
  return new Promise((resolve, reject) => {
    resolve.value = resolve
    reject.value = reject
  })
}

function agree() {
  if (!confirmation.value || confirmation.value === confirmationTyped.value) {
    resolve(true)
    dialog.value = false
    confirmationError.value = false
    confirmationTyped.value = null
  } else {
    confirmationError.value = true
  }
}

function cancel() {
  confirmationTyped.value = null;
  confirmationError.value = false;
  resolve(false)
  dialog.value = false
}

</script>