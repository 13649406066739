<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="t('catalog.collection.duplicate.title', {label: toDuplicate.label})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="collection.code" :edit="true" class="mb-6"
                     :label="t('catalog.collection.duplicate.new-code')"/>
      <v-text-field v-model="collection.label" :label="t('catalog.collection.duplicate.new-label')"
                    variant="outlined" bg-color="white"/>
      <v-combobox v-model="tags" :items="[]" :label="t('catalog.collection.duplicate.new-tags')" multiple chips
                  closable-chips hide-details variant="outlined" style="background-color: white"></v-combobox>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="duplicate">{{ t('common.duplicate') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
  toDuplicate: Object
})

let {t} = useI18n()
let collection = ref({})
let tags = ref([])

const emit = defineEmits(['canceled', 'duplicated'])

function cancel() {
  emit('canceled')
}

function duplicate() {
  collection.value.tags = tags.value;
  emit("duplicated", collection.value)
}
</script>