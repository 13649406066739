<template>
    <CreateComponent type="dataset" :show-import-mode="false"
                     @canceled="$emit('canceled')" @saved="$emit('saved', dataset)"
                     @imported="importJson">
        <template v-slot:form>
            <v-card-text>
                <CodeTextField v-model="dataset.code" :edit="true" class="mb-6"/>
                <v-text-field v-model="dataset.label" :label="t('common.label')" variant="outlined" bg-color="white"/>
            </v-card-text>
        </template>
    </CreateComponent>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import CreateComponent from "@/components/common/CreateComponent.vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

let {t} = useI18n()
const dataset = ref({})
const emit = defineEmits(['canceled', 'saved', 'imported'])
const importJson = (importedDataset) => {
    delete importedDataset.mode
    emit("imported", importedDataset)
}
</script>