<template>
    <Components :components="collections" :enable-tags="true" :component-label="t('catalog.collection.label', 1)"
                :create-component-label="t('catalog.collection.create-or-import')" :allow-creation="true"
                @create="createCollection = true" @navigate="navigateToCollection"/>
    <Loading :loading="loading" :text="t('catalog.collection.loading-collections')"/>
    <v-dialog v-model="createCollection" persistent>
        <CreateComponent type='collection' :show-import-mode="true"
                         @canceled="createCollection = false" @saved="doCreateCollection" @imported="doImportCollection">
            <template v-slot:form>
                <v-card-text>
                    <CodeTextField v-model="collection.code" :edit="true" class="mb-6"/>
                    <v-text-field v-model="collection.label" :label="t('common.label')" variant="outlined"
                                  bg-color="white"/>
                    <v-combobox v-model="tags" :items="[]" :label="t('catalog.collection.tags')" multiple chips
                                closable-chips hide-details variant="outlined"
                                style="background-color: white"></v-combobox>
                </v-card-text>
            </template>
        </CreateComponent>
    </v-dialog>
</template>
<script setup>
import { toast } from 'vue3-toastify';
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";
import CreateComponent from "@/components/common/CreateComponent.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

let collections = ref([]);
let createCollection = ref(false);
let collection = ref({});
let tags = ref([]);
let filter = ref("");
let loading = ref(true);

let catalogApi = useCatalogApi();

catalogApi.$collection.list()
  .then(result => collections.value = result)
  .finally(() => loading.value = false)

let {t} = useI18n()

function doCreateCollection() {
  createCollection.value = false;
  collection.value.tags = tags.value;
  catalogApi.$collection.create(collection.value)
    .then(created => {
      toast.success(t('catalog.collection.created', {code: created.code}))
      navigateToCollection(created.code);
    })
}

function doImportCollection(collectionImport) {
  createCollection.value = false;
  catalogApi.$collection.importCollection(collectionImport)
    .then(created => {
      toast.success(t('catalog.collection.imported', {code: created.code}))
      navigateToCollection(created.code);
    })
}

let router = useRouter()

function navigateToCollection(collectionCode) {
  router.push({name: 'collection', params: {collectionCode: collectionCode}})
}
</script>