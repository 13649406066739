<template>
  <Loading :loading="loading" :text="t('catalog.variable.matrix.loading-matrix-variable')"/>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="t('catalog.variable.matrix.configure')"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <div v-for="(column, index) in tempValue" class="mb-4">
          <div v-if="!loading" class="pa-4 border border-opacity-50 border rounded">
            <div class="d-flex justify-space-between">
              <v-label>{{ t('catalog.variable.matrix.column', {index: index + 1}) }} :</v-label>
              <v-btn color="error" variant="text" size="small" @click="removeColumn(index)">
                <v-icon class="mr-2">mdi-delete</v-icon>
                <span>{{ t('catalog.variable.matrix.delete-column') }}</span>
              </v-btn>
            </div>
            <v-autocomplete v-model="column.variable" class="mt-3" :label="t('catalog.variable.label')"
                            variant="outlined" bg-color="white" item-value="reference" :items="variablesList()"
                            :rules="[v => v?.length > 0 || t('validation.required', {label: t('catalog.variable.label')})]"
                            :item-title="itemLabel"
                            @update:modelValue="(i) => updateColumn(column, i)"
            >
            </v-autocomplete>
            <!--            <v-alert v-if="hasMessage(column.variable)" class="mb-6" color="warning" variant="outlined"-->
            <!--                     icon="mdi-information" prominent type="warning" :text="getMessage(column.variable)">-->
            <!--            </v-alert>-->
            <div v-if="column.variable">
              <v-text-field v-model="column.label" :label="t('common.label')"
                            variant="outlined" bg-color="white" hide-details/>

              <div v-if="column.variableType === 'BOOLEAN'" class="mt-4">
                <span class="ml-2 mr-4">{{ t('catalog.variable.values') }} : </span>
                <span>{{ t('common.yes') }} / {{ t('common.no') }}</span>
              </div>
              <div v-else-if="isFromReferential(column.variable)" class="mt-3">
                <div class="d-flex justify-space-between align-center">
                  <span class="ml-2 mr-4">{{ t('catalog.variable.values') }} : </span>
                  <v-radio-group v-model="column.valuesOption" inline hide-details color="primary">
                    <v-radio :label="t('catalog.variable.select-values')" value="selected"/>
                    <v-radio :label="getAllValuesLabel(column.variable)" value="all"/>
                  </v-radio-group>
                </div>
                <v-autocomplete v-if="column.valuesOption === 'selected'" v-model="column.values"
                                :rules="[v => v?.length > 0 || t('validation.not-empty')]"
                                :label="t('catalog.variable.values')" class="mt-4"
                                :items="column.validValues" multiple chips closable-chips :loading="column.loading"
                                variant="outlined" bg-color="white" clearable/>
              </div>
              <div v-else-if="column.variableType === 'STRING'" class="mt-3">
                <div v-if="hasValues(column.variable)" class="mt-3">
                  <div class="d-flex justify-space-between align-center">
                    <span class="ml-2 mr-4">{{ t('catalog.variable.values') }} : </span>
                    <v-radio-group v-model="column.valuesOption" inline hide-details color="primary">
                      <v-radio :label="t('catalog.variable.select-values')" value="selected"/>
                      <v-radio :label="t('catalog.variable.load-all-values')" value="all"/>
                    </v-radio-group>
                  </div>
                  <v-autocomplete v-if="column.valuesOption === 'selected'" v-model="column.values"
                                  :rules="[v => v?.length > 0 || t('validation.not-empty')]"
                                  :label="t('catalog.variable.values')" class="mt-4"
                                  :items="column.validValues" multiple chips closable-chips
                                  variant="outlined" bg-color="white" clearable/>
                </div>
                <v-combobox v-else v-model="column.values" class="mt-6" :items="[]"
                            multiple chips :label="t('catalog.variable.valid-values')" variant="outlined"
                            :rules="[v => v?.length > 0 || t('validation.not-empty')]"
                            :placeholder="t('common.add-value-to-combobox')"
                            bg-color="white" closable-chips clearable/>
              </div>
              <div v-else-if="column.variableType === 'DATE'" class="mt-3">
                <MatrixColumnValues v-model="column.values" :variable="getVariable(column.variable)"/>
              </div>
              <div v-else-if="column.variableType === 'NUMBER'" class="mt-3">
                <MatrixColumnValues v-model="column.values" :variable="getVariable(column.variable)"/>
              </div>
            </div>
          </div>
        </div>
      </v-form>
      <v-btn v-if="variablesList().length > 0" color="primary" variant="text" size="small" @click="addColumn">
        {{ t('catalog.variable.matrix.add-column') }}
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import {useI18n} from "vue-i18n";
import {onMounted, ref} from "vue";
import {useCatalogApi} from "@/composables/useCatalogApi";

import {arraysHaveSameElements, computeVariableReference, deepClone, getParent} from "@/util";
import Loading from "@/components/common/Loading.vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import MatrixColumnValues from "@/components/catalog/MatrixColumnValues.vue";

let props = defineProps({
  variableReference: String,
  modelValue: Array
})
let {t} = useI18n()
const emit = defineEmits(['closed', 'update:modelValue'])

const tempValue = ref(deepClone(props.modelValue));

const form = ref(null)
const loading = ref(true)
const catalogApi = useCatalogApi()
const referentialApi = useReferentialApi()
const variables = ref({})

onMounted(() => {
  catalogApi.$variable.getUsableVariables(props.variableReference, true)
    .then(response => {
      variables.value = response.reduce((acc, item) => {
        acc[item.reference.replaceAll('/', '_')] = item;
        return acc;
      }, {})
      tempValue.value.forEach(item => {
        let variable = getVariable(item.variable);
        item.variableType = variable.variableType;
        fetchValues(item, variable)
      });
      loading.value = false
    })
})

function itemLabel(item) {
  if (item.variableType) {
    return `${item.reference} (${t('catalog.variable.type.' + item.variableType.toLowerCase())})`
  }
  return item
}

function getVariable(reference) {
  const code = reference.replaceAll('/', '_')
  let variable = variables.value[code]
  variable.code = code
  return variable
}

function isFromReferential(reference) {
  const variable = getVariable(reference);
  return variable.type === 'DATASET_PROPERTY' || variable.type === 'CLASSIFIER_PROPERTY';
}

function variablesList() {
  return Object.values(variables.value)
    .filter(v => !tempValue.value.some(c => c.variable === v.reference))
    .sort((a, b) => a.reference.localeCompare(b.reference))
}

function addColumn() {
  tempValue.value.push({})
}

function removeColumn(index) {
  tempValue.value.splice(index, 1);
}

function updateColumn(column, reference) {
  const variable = getVariable(reference)
  column.code = variable.code
  column.variableType = variable.variableType
  column.label = getVariableLabel(variable)
  column.values = []
  column.validValues = []
  fetchValues(column, variable)
}

function fetchValues(column, variable) {
  if (variable.type === 'DIRECT' || variable.type === 'LOOP') {
    if (variable.variableType === 'STRING' && variable.variable.validValues) {
      column.valuesOption = arraysHaveSameElements(variable.variable.validValues, column.values) ? 'all' : 'selected'
      column.validValues = variable.variable.validValues
    } else if (variable.variableType === 'BOOLEAN') {
      column.values = [true, false]
    }
  } else if (variable.type === 'DATASET_PROPERTY') {
    column.loading = true
    referentialApi.$dataset.propertyValues(variable.variable.datasetCode, variable.propertyCode)
      .then(result => {
        column.valuesOption = arraysHaveSameElements(result, column.values) ? 'all' : 'selected'
        column.validValues = result
      })
      .finally(() => column.loading = false)
  } else if (variable.type === 'CLASSIFIER_PROPERTY') {
    column.loading = true
    if (variable.composite) {
      if (variable.variableType === 'BOOLEAN') {
        column.values = [true, false]
      } else {
        referentialApi.$classifier.propertyValues(variable.classifierCode, variable.propertyCode)
          .then(result => {
            column.valuesOption = arraysHaveSameElements(result, column.values) ? 'all' : 'selected'
            column.validValues = result
          })
          .finally(() => column.loading = false)
      }
    } else if (variable.variableType === 'BOOLEAN') {
      column.values = [true, false]
    } else {
      referentialApi.$classifier.propertiesValues(variable.classifierCode, {})
        .then(result => {
          column.valuesOption = arraysHaveSameElements(result.value, column.values) ? 'all' : 'selected'
          column.validValues = result.value
        })
        .finally(() => column.loading = false)
    }
  }
}

function getVariableLabel(variable) {
  if (variable.type === 'DIRECT' || variable.type === 'LOOP') {
    return variable.variable.label
  } else if (variable.type === 'DATASET_PROPERTY' || variable.type === 'CLASSIFIER_PROPERTY') {
    return variable.propertyLabel
  }
  return null
}

function hasMessage(reference) {
  if (reference) {
    const variable = getVariable(reference)
    return variable?.type === 'LOOP' || variable?.type === 'DATASET_PROPERTY' || variable?.type === 'CLASSIFIER_PROPERTY'
  }
  return false
}

function getMessage(reference) {
  if (reference) {
    const variable = getVariable(reference)
    if (variable.type === 'LOOP') {
      return t('catalog.variable.matrix.loop-over-variable-alert', {code: getParent(reference)})
    }
    if (variable.type === 'DATASET_PROPERTY' || variable.type === 'CLASSIFIER_PROPERTY') {
      return t('catalog.variable.matrix.record-variable-alert', {code: computeVariableReference(variable.variable)})
    }
  }
  return null
}

function hasValues(reference) {
  if (reference) {
    const variable = getVariable(reference)
    if (variable.variableType === 'STRING' && (variable.type === 'DIRECT' || variable.type === 'LOOP')) {
      return variable.variable.validValues?.length > 0
    }
  }
  return false
}

function getAllValuesLabel(reference) {
  if (reference) {
    const variable = getVariable(reference)
    if (variable.type === 'DATASET_PROPERTY') {
      return t('catalog.variable.load-all-values-from-dataset')
    }
    if (variable.type === 'CLASSIFIER_PROPERTY') {
      return t('catalog.variable.load-all-values-from-classifier')
    }
    if (variable.type === 'DIRECT' || variable.type === 'LOOP') {
      return t('catalog.variable.load-all-values')
    }
  }
  return null
}

function cancel() {
  emit("closed", false);
}

async function save() {
  const {valid} = await form.value.validate()
  if (valid) {
    const sanitizedArray = tempValue.value.map(item => {
      return {
        code: item.code,
        variable: item.variable,
        variableType: item.variableType,
        label: item.label,
        values: item.valuesOption === 'all' ? item.validValues : item.values
      };
    });
    emit('update:modelValue', sanitizedArray);
    emit('closed', true)
  }
}
</script>