<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ t('referential.classifier.properties.label') }}
          ({{
            classifier && classifier.properties && classifier.properties.length > 0 ? classifier.properties.length : 0
          }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="my-2">
              <v-spacer/>
              <v-btn color="primary" @click="openProperty=true">{{
                  t('referential.classifier.properties.add')
                }}
              </v-btn>
            </v-row>
          </v-card-title>
          <v-row class="border my-1 pa-2 bg-primary" dense>
            <v-col cols="2">
              <span class="text-subtitle-1 font-weight-bold">{{ t('common.code') }}</span>
            </v-col>
            <v-col cols="4">
              <span class="text-subtitle-1 font-weight-bold">{{ t('common.label') }}</span>
            </v-col>
            <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    {{ t('referential.classifier.type.label') }}
                  </span>
            </v-col>
            <v-col>
            </v-col>
          </v-row>
          <div v-for="element in classifier.properties">
            <v-row align="center" class="border pa-2 bg-grey-lighten-5" dense>
              <v-col cols="2">
                <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{
                    element.code
                  }}</strong>
              </v-col>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
              </v-col>
              <v-col>
                <v-chip color="purple" label>
                    <span style="font-family: monospace, serif">
                      {{ t('referential.classifier.type.' + element.type.toLowerCase()) }}
                    </span>
                </v-chip>
              </v-col>
              <v-col class="d-flex flex-row justify-end align-center">
                <v-icon size="small" color="primary" @click="editProperty(element)">mdi-pencil</v-icon>
                <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2"
                       @click="deleteProperty(element)"/>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="openProperty" persistent>
    <ClassifierProperty :classifier-code="classifier.code" :property="propertyToEdit" @canceled="clearPropertyDialog"
                        @saved="saveProperty" @deleted="deleteProperty"/>
  </v-dialog>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import ClassifierProperty from "@/components/referential/ClassifierProperty.vue";
import {ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useI18n} from "vue-i18n";
import useConfirmDialog from "@/composables/useConfirmDialog";

const props = defineProps(['classifier'])

let panel = ref([])
let properties = ref([])
let openProperty = ref(false)
let propertyToEdit = ref({
  creation: true
})

function editProperty(property) {
  propertyToEdit.value = property;
  openProperty.value = true;
}

let referentialApi = useReferentialApi()
let {t} = useI18n()

function saveProperty(property) {
  let promise;
  if (property.creation) {
    promise = referentialApi.$classifier.addProperty(props.classifier.code, propertyToEdit.value)
  } else {
    promise = referentialApi.$classifier.updateProperty(props.classifier.code, propertyToEdit.value)
  }
  promise.then(saved => {
    props.classifier.properties = saved.properties
    toast.success(t('referential.classifier.properties.' + (property.creation ? 'created' : 'updated'), {code: property.code}))
  }).finally(() => clearPropertyDialog());
}

function deleteProperty(property) {
  useConfirmDialog(
    {
      title: t('referential.classifier.properties.delete-msg', {code: property.code}),
      message: t('referential.classifier.properties.confirm-delete-msg', {code: property.code})
    },
    () => referentialApi.$classifier.deleteProperty(props.classifier.code, property.code)
      .then(saved => {
        props.classifier.properties = saved.properties
        toast.warning(t('referential.classifier.properties.deleted', {code: property.code}))
      })
  )
}

function clearPropertyDialog() {
  openProperty.value = false;
  propertyToEdit.value = {
    creation: true
  };
}
</script>