<template>
  <v-dialog v-model="dialog" @keydown.esc="cancel" persistent>
    <v-card width="600px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="ma-6">
        <v-row justify="center">
          {{ message }}
        </v-row>
        <v-row v-if="confirmation" class="mt-8" align="center">
          <span class="mr-2" v-html="t('common.confirmation-text', {text: confirmation})"/>
          <v-text-field v-model:model-value="confirmationTyped" hide-details variant="outlined" density="compact"
                        :error="confirmationError" bg-color="white"/>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="cancel">{{ t('common.cancel') }}</v-btn>
        <v-btn color="primary darken-1" text @click="confirm">{{ t('common.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import {ref} from 'vue'
import {useI18n} from "vue-i18n";

let props = defineProps(['title', 'message', 'confirmation'])
let emit = defineEmits(['confirm', 'cancel'])
let confirmationTyped = ref('')
let confirmationError = ref(false)
let dialog = ref(true)
let {t} = useI18n()

function confirm() {
  if (!props.confirmation || props.confirmation === confirmationTyped.value) {
    emit('confirm')
  } else {
    confirmationError.value = true
  }
}

function cancel() {
  emit('cancel')
}
</script>