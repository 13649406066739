<template>
  <Components :components="collections" :enable-tags="true" :component-label="t('runner.collection.label', 1)"
              @navigate="navigateToCollection" :allow-creation="false" />
  <Loading :loading="loading" :text="t('runner.collection.loading-collections')" />
</template>
<script setup>
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";
import {onMounted, ref} from "vue";
import {useRunnerApi} from "@/composables/useRunnerApi";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

let collections = ref([])
let filter = ref('')
let loading = ref(true)
let {t} = useI18n()
let runnerApi = useRunnerApi()

onMounted(() => {
  runnerApi.$collection.list(null, true)
    .then(result => collections.value = result)
    .finally(() => loading.value = false)
})

let router = useRouter()

function navigateToCollection(collectionCode) {
  router.push({name: 'runnableCollection', params: {collectionCode: collectionCode}})
}
</script>