<template>
    <Loading :loading="loading" :text="t('referential.classifier.loading-classifiers')"/>
    <Components :components="classifiers" :enable-tags="true" :component-label="t('referential.classifier.label', 1)"
                :create-component-label="t('referential.classifier.create')" :allow-creation="true"
                @create="createClassifier = true" @navigate="navigateToClassifier"/>
    <v-dialog v-model="createClassifier" persistent>
        <CreateClassifier @canceled="createClassifier = false" @saved="doCreateClassifier" @imported="doCreateClassifier" />
    </v-dialog>
</template>
<script setup>
import { toast } from 'vue3-toastify'
import CreateClassifier from "@/components/referential/CreateClassifier.vue";
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";
import {onMounted, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

let classifiers = ref([])
let createClassifier = ref(false)
let loading = ref(true)

let referentialApi = useReferentialApi()

onMounted(() => {
  referentialApi.$classifier.list()
    .then(result => {
      classifiers.value = result
      classifiers.value.forEach(classifier => classifier.tags = [classifier.datasetCode])
    })
    .finally(() => loading.value = false)
})

let {t} = useI18n()

function doCreateClassifier(classifierToCreate) {
  createClassifier.value = false;
  referentialApi.$classifier.create(classifierToCreate)
    .then(created => {
      toast.success(t('referential.classifier.created', {code: created.code}))
      navigateToClassifier(created.code);
    })
}

let router = useRouter()

function navigateToClassifier(classifierCode) {
  router.push({name: 'classifier', params: {classifierCode: classifierCode}})
}
</script>