<template>
    <ModuleHeader code="runner"/>
    <v-tabs v-model="tab" color="primary">
        <v-tab value="collections">
            {{ t('runner.collection.label', 2) }}
        </v-tab>
    </v-tabs>
    <v-window v-model="tab">
        <v-window-item value="collections">
            <v-card class="mx-auto">
                <Collections/>
            </v-card>
        </v-window-item>
    </v-window>
</template>
<script setup>
import ModuleHeader from "@/components/common/ModuleHeader.vue";
import Collections from "@/components/runner/Collections.vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

let {t} = useI18n()
let tab = ref('collections')
</script>