<template>
  <v-card-actions class="align-center">
    <Breadcrumb :reference="$route.params.collectionCode"/>
    <v-spacer/>
    <ButtonWithTooltip icon="mdi-play" color="primary" :edit="edit" @clicked="simulateCollection"
                       size="large" density="comfortable"
                       :tooltip-text="t('catalog.collection.simulate')"
                       :label="t('common.simulate')"/>
    <ButtonWithTooltip icon="mdi-download" color="action" :edit="edit" @clicked="exportCollection"
                       size="large" density="comfortable"
                       :tooltip-text="t('common.export.title', {type: 'collection', label: collection.code})"
                       :label="t('common.export.label')"/>
    <v-divider vertical style="height: auto" class="mx-3"/>
    <ButtonWithTooltip icon="mdi-cube-send" color="action" :edit="edit" @clicked="release = true"
                       size="large" density="comfortable"
                       :tooltip-text="t('catalog.collection.publish')"
                       :label="t('common.publish')"/>
  </v-card-actions>
  <v-card class="mt-3" density="compact">
    <v-card-actions>
      <v-spacer/>
      <ComponentHistory :component="collection" :edit="edit"/>
      <v-divider vertical style="height: auto" class="mx-3"></v-divider>
      <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                         :tooltip-text="t('catalog.collection.edit')"/>
      <ButtonWithTooltip icon="mdi-content-copy" color="action" :edit="edit" @clicked="duplicate = true"
                         :tooltip-text="t('catalog.collection.duplicate.label')"/>
      <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteCollection"
                         :tooltip-text="t('catalog.collection.delete')"/>
    </v-card-actions>
    <v-card-text>

      <v-row align="center" justify="center">
        <v-col cols="5">
          <CodeTextField v-model="collection.code" :edit="false"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="collection.label" :label="t('common.label')" :readonly="!edit"
                        :variant="edit ? 'outlined' : 'plain'" hide-details
                        :bg-color="edit ? 'white' : ''"/>
        </v-col>
        <v-col cols="11">
          <v-combobox v-model="tags" :items="[]" :label="t('catalog.collection.tags')" :readonly="!edit" multiple chips
                      :closable-chips="edit"
                      :variant="edit ? 'outlined' : 'plain'" hide-details
                      :style="edit ? 'background-color: white' : ''"></v-combobox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="edit" color="error" @click="cancelUpdateCollection">{{ t('common.cancel') }}</v-btn>
      <v-btn v-if="edit" color="primary" @click="updateCollection">{{ t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
  <div v-if="collection.code">
    <Publications :key="publicationsKey" type="COLLECTION" :code="collection.code"/>
    <SubVariables :reference="collection.code" :label="collection.label" v-model="variables" is-collection/>
  </div>
  <Loading :loading="loading" :text="t('catalog.collection.loading-collection', {code: collection.code})"/>
  <v-dialog v-model="openJson" :persistent="true">
    <DisplayJson :code="collection.code" type="collection" :data="collectionExport" @closed="openJson = false"/>
  </v-dialog>
  <v-dialog v-model="duplicate" :persistent="true">
    <DuplicateCollection :to-duplicate="collection" @canceled="duplicate = false"
                         @duplicated="duplicateCollection"></DuplicateCollection>
  </v-dialog>
  <v-dialog v-model="release" :persistent="true">
    <CreatePublication :component=collection @canceled="release = false" @saved="releaseCollection"/>
  </v-dialog>
  <DialogsWrapper/>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import Breadcrumb from "@/components/catalog/CatalogBreadcrumb.vue";
import SubVariables from "@/components/catalog/SubVariables.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import ComponentHistory from "@/components/catalog/ComponentHistory.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import DuplicateCollection from "@/components/catalog/DuplicateCollection.vue";
import CreatePublication from "@/components/catalog/CreatePublication.vue";
import Publications from "@/components/catalog/Publications.vue";
import Loading from "@/components/common/Loading.vue";
import DisplayJson from "@/components/common/DisplayJson.vue";
import {onMounted, ref} from "vue";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useRoute, useRouter} from "vue-router";
import useConfirmDialog from "@/composables/useConfirmDialog";
import {useI18n} from "vue-i18n";
import {useCollectionStore} from "@/store/collection";

let collection = ref({})
let collectionExport = ref({})
let variables = ref([])
let tags = ref([])
let edit = ref(false)
let openJson = ref(false)
let duplicate = ref(false)
let release = ref(false)
let publicationsKey = ref(0)
let loading = ref(true)

let catalogApi = useCatalogApi();
let route = useRoute()
let router = useRouter()
let collectionStore = useCollectionStore()

onMounted(() => {
  catalogApi.$collection.find(route.params.collectionCode)
    .then(result => {
      collection.value = result;
      tags.value = collection.value.tags ? collection.value.tags : [];
      catalogApi.$variable.listCollectionVariables(route.params.collectionCode)
        .then(variablesList => variables.value = variablesList)
        .finally(() => loading.value = false);
      collectionStore.setCurrentCollection(collection.value.code)
    })
    .catch(() => router.push({name: 'catalog'}))
})

let {t} = useI18n()

function cancelUpdateCollection() {
  edit.value = !edit.value
  tags.value = collection.value.tags ? collection.value.tags : [];
}

function updateCollection() {
  collection.value.tags = tags.value;
  catalogApi.$collection.update(collection.value)
    .then(() => toast.success(t('catalog.collection.saved', {label: collection.value.label})))
    .finally(() => edit.value = false)
}

function deleteCollection() {
  useConfirmDialog(
    {
      title: t('catalog.collection.delete-msg', {label: collection.value.label}),
      message: t('catalog.collection.confirm-delete-msg', {label: collection.value.label}),
      confirmation: collection.value.code
    },
    () => {
      catalogApi.$collection.delete(collection.value.code)
        .then(deleteOK => {
          if (deleteOK) {
            toast.warning(t('catalog.collection.deleted', {label: collection.value.label}))
            router.push({name: 'catalog'});
          } else {
            toast.error(t('catalog.collection.error-on-delete', {label: collection.value.label}))
          }
        })
    }
  )
}

function simulateCollection() {
  router.push({name: 'simulation', params: {collectionCode: collection.value.code}})
}

function exportCollection() {
  catalogApi.$collection.exportCollection(collection.value.code)
    .then(response => collectionExport.value = response)
    .finally(() => openJson.value = !openJson.value)
}


function releaseCollection(publication) {
  catalogApi.$collection.publish(collection.value.code, publication)
    .then(result => {
      toast.success(t('catalog.collection.released', {label: collection.value.label}))
      if (publication.deploy) {
        catalogApi.$collection.deploy(collection.value.code, result.version)
          .then(r => {
            toast.success(t('catalog.collection.deployed', {label: collection.value.label}))
          })
      }
    })
    .finally(() => {
      release.value = false
      publicationsKey.value += 1
    })
}

function duplicateCollection(duplicateInfos) {
  catalogApi.$collection.duplicate(collection.value.code, duplicateInfos)
    .then(created => {
      toast.success(t('catalog.collection.duplicated', {
        label: created.label,
        origin: collection.value.code
      }))
      router.push({name: 'collection', params: {collectionCode: created.code}})
    })
}
</script>