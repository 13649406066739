<template>
  <json-viewer :value="user" boxed expanded></json-viewer>
  <v-btn v-if="user['ruleshake/roles'] && user['ruleshake/roles'].includes('QA')" color="primary" class="mt-2" @click="copyToken">Copy token</v-btn>
</template>
<script setup>
import {useAuth} from "@/composables/useAuth";
import {ref} from "vue";
import {useAuthStore} from "@/store/auth";
import {toast} from "vue3-toastify";

let auth = useAuth();
let user = ref({})
let token = null

auth.$auth.getUser()
  .then(u => {
    user.value = u.profile
    token = u.access_token
  })

const copyToken = () => {
  navigator.clipboard.writeText(token)
  toast.success("Token copied !")
}

</script>