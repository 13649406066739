<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-if="record.creation"
              v-html="t('referential.records.new-title', {code: classifier.code})"/>
        <span v-else v-html="t('referential.records.edit-title', {code: record.code})"/>
      </v-toolbar-title>
      <v-spacer/>
      <v-icon class="mr-4" @click="cancel">mdi-close</v-icon>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="record.code" :edit="record.creation" class="mb-6" :allow-start-by-number="true"/>
      <div v-if="classifier.type !== 'COMPOSITE'">
        <YesNoField v-if="classifier.type === 'BOOLEAN'" v-model="record.value.value" :label="t('common.value')"/>
        <v-text-field v-else v-model="record.value.value" :label="t('common.value')"
                      :type="classifier.type" variant="outlined" bg-color="white"/>
      </div>
      <div v-else>
        <div v-for="property in properties">
          <YesNoField v-if="property.type === 'BOOLEAN'" v-model="record.properties[property.code].value"
                      :label="property.label"/>
          <v-text-field v-else v-model="record.properties[property.code].value" :label="property.label"
                        :type="property.type" variant="outlined" bg-color="white"/>
        </div>
      </div>
      <v-card-title class="border border-opacity-50 border rounded">
        <v-label class="pl-2 mb-4">{{ t('referential.classifier.records.associated-dataset-keys') }} :</v-label>
        <br/>
        <v-row v-for="(datasetRecordKey, index) in record.datasetRecordsKeys" align="center">
          <v-col>
            <v-select v-model="datasetRecordKey.propertyCode" :items="keys" variant="outlined"
                      :label="t('common.key', 1)" dense hide-details bg-color="white"/>
          </v-col>
          <v-col>
            <v-autocomplete v-model="datasetRecordKey.propertyValue" :label="t('common.value')" hide-details
                            variant="outlined" bg-color="white" :items="values[datasetRecordKey.propertyCode]"/>
          </v-col>
          <v-icon size="small" color="error" class="mr-2" @click="deleteDatasetRecordKey(index)">mdi-delete</v-icon>
        </v-row>
        <v-btn @click="addDatasetRecordKey"
               :class="record.datasetRecordsKeys && record.datasetRecordsKeys.length > 0 ? 'mt-2' : ''" variant="text"
               color="primary">
          {{ t('referential.classifier.records.add-dataset-key') }}
        </v-btn>
      </v-card-title>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="!record.creation" color="error" @click="remove">{{ t('common.delete') }}</v-btn>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import YesNoField from "@/components/common/YesNoField.vue";
import {computed, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import useConfirmDialog from "@/composables/useConfirmDialog";
import {useI18n} from "vue-i18n";

const props = defineProps({
  record: {},
  classifier: {}
})

if (props.record.properties && Object.keys(props.record.properties).length > 0) {
  props.classifier.properties.forEach(property => {
    if (!props.record.properties[property.code]) {
      props.record.properties[property.code] = {
        value: null,
        type: property.type
      }
    }
  })
}

let types = ref(['STRING', 'NUMBER', 'BOOLEAN', 'DATE'])
let values = ref({})

if (!props.record.datasetRecordsKeys) {
  props.record.datasetRecordsKeys = []
}

let keys = computed(() => {
  if (props.classifier && props.classifier.keys) {
    return props.classifier.keys.map(k => k.propertyCode)
  }
  return []
})

let properties = computed(() => {
  if (props.classifier && props.classifier.type === 'COMPOSITE' && props.classifier.properties) {
    return props.classifier.properties
  }
  return []
})

let referentialApi = useReferentialApi()

keys.value.forEach(key => referentialApi.$dataset.propertyValues(props.classifier.datasetCode, key)
  .then(result => values.value[key] = result)
)

const emit = defineEmits(['canceled', 'saved', 'deleted'])

function cancel() {
  emit("canceled");
}

function save() {
  props.record.datasetRecordsKeys = props.record.datasetRecordsKeys.filter(d => d.propertyCode !== null);
  emit("saved", props.record)
}

let {t} = useI18n()

function remove() {
  useConfirmDialog(
    {
      title: t('referential.records.delete-msg', {code: props.record.code}),
      message: t('referential.records.confirm-delete-msg', {code: props.record.code})
    },
    () => emit("deleted", props.record)
  )
}

function addDatasetRecordKey() {
  props.record.datasetRecordsKeys.push({
    propertyCode: null,
    propertyValue: null
  })
}

function deleteDatasetRecordKey(index) {
  props.record.datasetRecordsKeys.splice(index, 1)
}
</script>