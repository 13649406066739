<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="subVariables">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ t('catalog.variable.provided-sub-variables.title') }}
          ({{ variables && variables.length > 0 ? variables.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <div>
            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-bold">{{ t('common.code') }}</span>
              </v-col>
              <v-col cols="5">
                <span class="text-subtitle-1 font-weight-bold">{{ t('common.label') }}</span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">{{ t('catalog.variable.nature.label') }}</span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">{{ t('catalog.variable.type.label') }}</span>
              </v-col>
            </v-row>
            <div v-for="element in variables">
              <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="4">
                  <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.code }}</strong>
                </v-col>
                <v-col cols="5">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col>
                  <v-chip color="purple" label><span style="font-family: monospace, serif">{{ t('catalog.variable.nature.' + element.nature.toLowerCase()) }}</span>
                  </v-chip>
                </v-col>
                <v-col>
                  <v-chip color="blue" label><span style="font-family: monospace, serif">{{ t('catalog.variable.type.' + element.type.toLowerCase()) }}</span>
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup>
import {ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useI18n} from "vue-i18n";

const props = defineProps({
  variable: undefined
})

let panel = ref(['subVariables'])
let variables = ref([])
let classifiers = ref(new Map())

let referentialApi = useReferentialApi()
let {t} = useI18n();

referentialApi.$dataset.find(props.variable.datasetCode)
  .then(result => {
    addSubVariable('CODE', t('common.code'), 'STRING', 'PROVIDED')
    result.properties.forEach(property => addSubVariable(property.code, property.label, property.type, 'PROVIDED'))
    referentialApi.$classifier.listByDataset(result.code)
      .then(result2 => {
        classifiers.value = new Map(result2.map(o => [o.code, o]))
        props.variable.classifiers?.sort((a, b) => a - b)
          .forEach(classifierVar => {
            const classifier = classifiers.value.get(classifierVar)
            addSubVariable(classifier.code, classifier.label, classifier.type, 'CLASSIFIER')
          })
      })
  })

function addSubVariable(code, label, type, nature) {
  variables.value.push({
    code: code,
    label: label,
    type: type,
    nature: nature
  })
}

</script>