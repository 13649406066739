<template>
    <Loading :loading="loading" :text="t('common.loading')" />
    <v-card v-if="module" class="mt-6 pt-4 px-6">
    <v-card-actions>
      <span class="text-h4 font-weight-bold">{{ module.toUpperCase() }} API Description</span>
      <v-spacer />
      <v-btn variant="flat" color="primary" @click="loadSwagger">{{ t('common.reload') }}</v-btn>
    </v-card-actions>
    <v-divider class="mt-4"/>
    <v-card-text>
      <div class="swagger" :id="module + 'swagger'"></div>
    </v-card-text>
  </v-card>
</template>
<script setup>
import { SwaggerUIBundle } from "swagger-ui-dist"
import 'swagger-ui-dist/swagger-ui.css';
import Loading from "@/components/common/Loading.vue";
import {useApi} from "@/composables/useApi";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps(['module'])

let panel = ref([])
let loading = ref(true)
let {t} = useI18n()
loadSwagger()

function loadSwagger() {
  let api = useApi();
  api.$support.getSwaggerSpec(props.module)
    .then(spec => SwaggerUIBundle({
      spec: spec,
      dom_id: `#${props.module}swagger`,
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset,
      ],
    }))
    .finally(() => loading.value = false)
}
</script>
