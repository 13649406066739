<template>
  <div class="d-flex flex-row align-center">
    <v-text-field v-model="model" bg-color="white" hide-details variant="outlined"
                  :label="t('referential.dataset.records.record-from-dataset', {dataset: datasetCode})" />
    <v-icon class="ml-4 mr-2" size="x-large" @click="dialog = !dialog">mdi-magnify</v-icon>
  </div>
  <v-dialog v-model="dialog" persistent>
    <v-card width="1440px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ t('referential.dataset.records.select-record') }}</v-toolbar-title>
        <v-spacer />
        <v-icon class="mr-4" @click="close">mdi-close</v-icon>
      </v-toolbar>
      <DatasetRecordSearch :dataset-code="datasetCode" @selected="update" />
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" @click="close">{{ t('common.cancel') }}</v-btn>
        <v-btn :disabled="!selected" color="primary" @click="select">{{ t('common.select') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import DatasetRecordSearch from "@/components/referential/DatasetRecordSearch.vue";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
  datasetCode: null,
  modelValue: false,
})

let {t} = useI18n()
let dialog = ref(false)
let selected = ref(null)

let model = computed({
  get() { return props.modelValue },
  set(newValue) { emit(newValue) }
})

function close() {
  dialog.value = false
  selected.value = null
}

function update(record) {
  selected.value = record
}

function select() {
  doEmit(selected.value.code)
  close()
}

const emit = defineEmits(['update:modelValue'])

function doEmit(recordCode) {
  emit("update:modelValue", recordCode);
}
</script>