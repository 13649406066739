<template>
  <span class="text-body-1 font-weight-bold">{{ t('catalog.variable.matrix.matrix') }} : </span>
  <div class="d-flex flex-column justify-center mt-2">
    <v-data-table class="mt-3" :headers="headers" :items="model.lines">
      <template v-slot:item="{ item }">
        <tr v-if="item.data">
          <td v-for="key in Object.keys(item.data)">
            <span v-html="displayItem(key, item.data[key])"></span>
          </td>
          <td class="d-flex align-center justify-space-between">
            <v-switch v-if="type === 'BOOLEAN'" v-model="item.value" hide-details color="primary" inset
                      :disabled="!edit"/>
            <v-text-field v-else-if="edit" v-model="item.value" hide-details variant="outlined" density="compact"
                          :type="type" style="background-color: white"/>
            <span v-else>{{ item.value }}</span>
            <v-btn v-if="edit" icon @click="removeItem(item)" size="small" color="error" variant="text" class="ml-2">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-if="edit" class="d-flex justify-center mt-4">
      <v-btn color="error" @click="remove" class="mr-4" variant="text" size="small">
        <v-icon class="mr-2">mdi-delete</v-icon>
        <span>{{ t('catalog.variable.matrix.remove') }}</span>
      </v-btn>
      <v-btn color="primary" @click="editMatrix=true" variant="text" size="small">
        <v-icon class="mr-2">mdi-pencil</v-icon>
        <span>{{ t('catalog.variable.matrix.configure') }}</span>
      </v-btn>
    </div>
  </div>
  <v-dialog v-model="editMatrix" persistent>
    <MatrixEdition :variable-reference="variableReference" v-model="model.columns"
                   @closed="(updated) => updateMatrix(updated)"/>
  </v-dialog>
</template>
<script setup>
import {computed, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import MatrixEdition from "@/components/catalog/MatrixEdition.vue";
import {useDisplay} from "@/composables/useDisplay";

let model = defineModel({
  default: () => reactive({
    columns: [],
    lines: []
  })
})

let props = defineProps({
  edit: Boolean,
  type: String,
  variableReference: String
})

console.log("type", props.type)

let editMatrix = ref(false)
let {t} = useI18n()
const {display} = useDisplay()

const headers = computed(() => {
  let h = model.value.columns.map(c => {
    return {
      title: c.label,
      key: "data." + c.code
    }
  })
  h.push({
    title: "Value",
    key: "value"
  })
  return h
})

function updateMatrix(updated) {
  editMatrix.value = false
  if (updated) {
    model.value.lines = generateCombinations(model.value.columns)
  }
}

function generateCombinations(columns) {
  function combine(prefix, index) {
    if (index === columns.length) {
      result.push({
        data: Object.assign({}, prefix),
        value: undefined
      });
      return;
    }

    const column = columns[index];
    for (const value of column.values) {
      prefix[column.code] = value;
      combine(prefix, index + 1);
    }
  }

  const result = [];
  if (columns.length > 0) {
    combine({}, 0);
  }
  return result;
}

function removeItem(item) {
  model.value.lines.splice(model.value.lines.indexOf(item), 1)
}

function displayItem(key, value) {
  const definition = model.value.columns.find(c => c.code === key)
  return display(definition.variableType, value);
}

const emit = defineEmits(['remove'])

function remove() {
  emit("remove");
}

</script>