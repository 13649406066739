<template>
  <Loading :loading="loading" :text="t('runner.collection.loading-collection', {code: route.params.collectionCode})"/>
  <v-card-actions class="align-center">
    <Breadcrumb :reference="route.params.collectionCode"/>
    <v-spacer/>
    <v-btn variant="text" color="primary" size="large" density="comfortable"
           :to="'/catalog/collection/' + route.params.collectionCode">
      {{ t('runner.collection.go-to-definition') }}
    </v-btn>
  </v-card-actions>
  <v-card class="mt-3" density="compact">
    <v-card-text class="mt-4 mb-2">
      <v-row align="center" justify="center">
        <v-col cols="5">
          <CodeTextField v-model="last().code" :edit="false"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="last().label" :label="t('common.label')" readonly variant="plain" hide-details/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <EvaluationCodeSnippet :collection-code="route.params.collectionCode"/>
  <Publications :collection-code="route.params.collectionCode" :publications="collections"/>
</template>
<script setup>
import Breadcrumb from "@/components/runner/RunnerBreadcrumb.vue";
import Loading from "@/components/common/Loading.vue";
import {useRoute} from "vue-router";
import {ref} from "vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import Publications from "@/components/runner/Publications.vue";
import {useRunnerApi} from "@/composables/useRunnerApi";
import EvaluationCodeSnippet from "@/components/runner/EvaluationCodeSnippet.vue";
import {useI18n} from "vue-i18n";

let {t} = useI18n()
let loading = ref(true)
let collections = ref([])

let route = useRoute()
let runnerApi = useRunnerApi()

runnerApi.$collection.list(route.params.collectionCode)
  .then(result => {
    collections.value = result.sort((a, b) => (a.version > b.version) ? -1 : 1)
    loading.value = false
  });
const last = () => {
  if (collections.value.length > 0) {
    return collections.value.at(0)
  }
  return {}
}
</script>