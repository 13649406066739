import {useDate} from "vuetify";
import {useI18n} from "vue-i18n";

export function useDisplay() {
    const dateFormatter = useDate()
    let {t} = useI18n()

    const display = (variableType, value) => {
        if (variableType === 'DATE') {
            return displayDate(value)
        }
        if (variableType === 'NUMBER') {
            return displayNumber(value)
        }
        if (variableType === 'BOOLEAN') {
            return value ? t('common.yes') : t('common.no')
        }
        return value
    }

    function displayDate(date) {
        if (date.startsWith("[")) {
            const dates = date.match(/\d{4}-\d{2}-\d{2}/g)
            if (dates && dates.length === 2) {
                const [startDate, endDate] = dates;
                return t('catalog.variable.matrix.between', {
                    v1: `<b><code>${dateFormatter.format(startDate, "keyboardDate")}</code></b>`,
                    v2: `<b><code>${dateFormatter.format(endDate, "keyboardDate")}</code></b>`
                });
            }
        }
        if (date.startsWith("<") || date.startsWith(">") || date.startsWith("<=") || date.startsWith(">=")) {
            const match = date.match(/^(>=|<=|>|<)(\d{4}-\d{2}-\d{2})$/);
            if (match) {
                const operator = match[1];
                const d = match[2];
                return `${t('common.' + operator)} <b><code>${dateFormatter.format(d, "keyboardDate")}</code></b>`;
            }
        }
        return date;
    }

    function displayNumber(number) {
        if (typeof number === 'number') {
            return `<b><code>${number}</code></b>`;
        }
        if (number.startsWith("[")) {
            const numbers = number.match(/\d+/g)
            if (numbers && numbers.length === 2) {
                const [startNumber, endNumber] = numbers;
                return t('catalog.variable.matrix.between', {
                    v1: `<b><code>${startNumber}</code></b>`,
                    v2: `<b><code>${endNumber}</code></b>`
                });
            }
        }
        if (number.startsWith("<") || number.startsWith(">") || number.startsWith("<=") || number.startsWith(">=")) {
            const match = number.match(/^(>=|<=|>|<)(\d+)$/);
            if (match) {
                const operator = match[1];
                const v = match[2];
                return `${t('common.' + operator)} <b><code>${v}</code></b>`;
            }
        }
        return `<b><code>${number}</code></b>`;
    }

    return {
        display,
    };

}