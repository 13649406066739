<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ t('catalog.publication.label', 2) }}
          ({{ publications && publications.length > 0 ? publications.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <div v-if="publications && publications.length > 0">
            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ t('common.version') }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ t('common.start-date') }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ t('common.end-date') }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ t('common.status') }}</span>
              </v-col>
            </v-row>
            <div v-for="element in publications">
              <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="3">
                  <strong class="ml-2" style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.version }}</strong>
                </v-col>
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-regular">{{ element.availability.start }}</span>
                </v-col>
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-regular">{{ element.availability.end }}</span>
                </v-col>
                <v-col cols="3">
                  <v-chip color="blue" label>
                    <span style="font-family: monospace, serif">
                      {{ t('catalog.publication.status.' + element.status.toLowerCase()) }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-alert v-else type="info" prominent variant="outlined">
            <span class="text-subtitle-1 mb-6 mt-3"
                  v-html="t('catalog.publication.has-no-publications-alert', {type: type.toLowerCase(), code: code})"/>
          </v-alert>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup>
import {ref, onMounted} from "vue";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useI18n} from "vue-i18n";

let props = defineProps(['code', 'type'])
let panel = ref([])
let publications = ref([])

let catalogApi = useCatalogApi()
let {t} = useI18n()

onMounted(() => {
  catalogApi.$collection.listPublications(props.code)
    .then(result => publications.value = result.sort((a, b) => (a.version > b.version) ? -1 : 1))
})
</script>