<template>
  <v-combobox v-model="model" :items="[]"
              :label="variable.variableType === 'NUMBER' ? t('catalog.variable.matrix.select-numbers') : t('catalog.variable.matrix.select-dates')"
              multiple clearable bg-color="white" readonly variant="outlined"
              :rules="[v => v?.length > 0 || t('validation.not-empty')]">
    <template v-slot:selection="{ item, index }">
      <v-chip closable @click:close="model.splice(index, 1)" size="small">
        <span v-html="displayItem(item.value)"/>
      </v-chip>
    </template>
    <template v-slot:append>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn color="primary" v-bind="props">
            <v-icon>mdi-plus</v-icon>
            <v-icon v-if="variable.variableType === 'NUMBER'">mdi-numeric</v-icon>
            <v-icon v-if="variable.variableType === 'DATE'">mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-card width="720px">
          <v-card-text class="d-flex flex-column">
            <v-btn-toggle v-model="selected.operator" class="mx-auto border mb-4" color="primary" divided
                          @update:model-value="selected.value = undefined; selected.maxValue = undefined; selected.comparator = undefined"
            >
              <v-btn value="exact">
                <span>{{ t('common.value') }}</span>
                <v-icon v-if="variable.variableType === 'NUMBER'" end>mdi-numeric</v-icon>
                <v-icon v-if="variable.variableType === 'DATE'" end>mdi-calendar</v-icon>
              </v-btn>

              <v-btn value="compare">
                <span>{{ t('common.compare') }}</span>
                <v-icon end>mdi-compare-horizontal</v-icon>
              </v-btn>

              <v-btn value="interval">
                <span>{{ t('common.interval') }}</span>
                <v-icon v-if="variable.variableType === 'NUMBER'" end>mdi-code-brackets</v-icon>
                <v-icon v-if="variable.variableType === 'DATE'" end>mdi-calendar-range</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-if="selected.operator === 'compare'"
                          v-model="selected.comparator" divided
                          class="mx-auto fill-height border mb-4" color="primary">
              <v-btn value="<" class="pa-4" prepend-icon="mdi-less-than">
                <span v-if="selected.comparator === '<'">{{ t('common.<') }}</span>
              </v-btn>

              <v-btn value="<=" class="pa-4" prepend-icon="mdi-less-than-or-equal">
                <span v-if="selected.comparator === '<='">{{ t('common.<=') }}</span>
              </v-btn>

              <v-btn value=">" class="pa-4" prepend-icon="mdi-greater-than">
                <span v-if="selected.comparator === '>'">{{ t('common.>') }}</span>
              </v-btn>

              <v-btn value=">=" class="pa-4" prepend-icon="mdi-greater-than-or-equal">
                <span v-if="selected.comparator === '>='">{{ t('common.>=') }}</span>
              </v-btn>
            </v-btn-toggle>
            <div v-if="variable.variableType === 'DATE'">
              <v-date-input v-model="selected.value"
                            @update:model-value="() => selected.maxValue = undefined"
                            :min="getMinValue()"
                            :max="getMaxValue()"
                            variant="outlined"
                            :label="selected.operator === 'interval' ? t('catalog.variable.matrix.select-start-date') : t('catalog.variable.matrix.select-date')"
              />
              <v-date-input v-if="selected.operator === 'interval'"
                            v-model="selected.maxValue"
                            :min="selected.value"
                            :max="getMaxValue()"
                            :disabled="selected.value === null"
                            variant="outlined"
                            :label="t('catalog.variable.matrix.select-end-date')"
              />
            </div>
            <div v-else>
              <v-number-input v-model="selected.value" inset
                              @update:model-value="() => selected.maxValue = undefined"
                              :min="getMinValue()"
                              :max="getMaxValue()"
                              :step="getPace()"
                              variant="outlined"
                              control-variant="split"
                              :label="selected.operator === 'interval' ? t('catalog.variable.min-value') : t('common.value')"
              />
              <v-number-input v-if="selected.operator === 'interval'" inset
                              v-model="selected.maxValue"
                              :min="selected.value"
                              :max="getMaxValue()"
                              :disabled="selected.value === null"
                              :step="getPace()"
                              variant="outlined"
                              control-variant="split"
                              :label="t('catalog.variable.max-value')"
              />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" variant="flat" :disabled="!canAdd()"
                   @click="add()">
              {{ t('common.add') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </v-combobox>
</template>
<script setup>

import {ref} from "vue";
import {useDisplay} from "@/composables/useDisplay";
import {useI18n} from "vue-i18n";

const model = defineModel()
const props = defineProps(['variable'])
const selected = ref({
  operator: 'exact',
})

let {t} = useI18n()
let {display} = useDisplay()

function displayItem(value) {
  return display(props.variable.variableType, value);
}

function canAdd() {
  if (selected.value.operator === 'interval') {
    return selected.value.value !== undefined && selected.value.maxValue
  }
  if (selected.value.operator === 'compare') {
    return selected.value.value !== undefined && selected.value.comparator
  }
  return selected.value.value !== undefined
}

function add() {
  if (selected.value?.operator === 'interval') {
    model.value.push(`[${format(selected.value.value)} .. ${format(selected.value.maxValue)}]`);
    return;
  }
  if (selected.value?.operator === 'compare') {
    model.value.push(`${selected.value.comparator}${format(selected.value.value)}`);
    return;
  }
  let formattedDate = format(selected.value.value);
  if (model.value.includes(formattedDate)) {
    return;
  }
  model.value.push(formattedDate)
}

function format(value) {
  if (props.variable.variableType === 'DATE') {
    const d = new Date(value);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return value;
}

function getMinValue() {
  if (props.variable.variableType === 'DATE') {
    return props.variable.variable.minDate?.value || null;
  }
  return props.variable.variable.minValue?.value || null;
}

function getMaxValue() {
  if (props.variable.variableType === 'DATE') {
    return props.variable.variable.maxDate?.value || null;
  }
  return props.variable.variable.maxValue?.value || null;
}

function getPace() {
  if (props.variable.variableType === 'NUMBER') {
    return props.variable.variable.pace?.value || 1;
  }
  return 1;
}

</script>