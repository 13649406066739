<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="t('catalog.variable.create-under', {parent: parent})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="code" :edit="true" class="mb-6"/>
      <v-text-field v-model="label" :label="t('common.label')" variant="outlined" bg-color="white"/>
      <v-select v-model="nature" :items="natures" :label="t('catalog.variable.nature.label')" variant="outlined"
                bg-color="white" return-object :item-title="(n) => t('catalog.variable.nature.'.concat(n.toLowerCase()))" ></v-select>
      <v-select v-model="type" :items="types" :label="t('catalog.variable.type.label')" variant="outlined"
                bg-color="white" return-object :item-title="(tt) => t('catalog.variable.type.'.concat(tt.toLowerCase()))"></v-select>
      <v-select v-if="type === 'RECORD'" v-model="dataset" :items="datasets" item-value="code"
                :item-title="datasetItemTitle" :label="t('catalog.variable.related-dataset')" variant="outlined"
                bg-color="white" return-object></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useReferentialApi} from "@/composables/useReferentialApi";

const props = defineProps(['parent'])

let {t} = useI18n()

let code = ref(null)
let type = ref(null)
let label = ref(null)
let nature = ref(null)
let dataset = ref(null)
let datasets = ref([])
let natures = ref(["INGRESS", "COMPUTED"])
let types = ref(['COMPOSITE', 'STRING', 'NUMBER', 'BOOLEAN', 'DATE', 'RECORD'])

let referentialApi = useReferentialApi()

watch(type, (selectedType) => {
    if (selectedType === 'RECORD') {
      referentialApi.$dataset.list()
        .then(result => datasets.value = result)
    }
})

const emit = defineEmits(['canceled', 'saved'])

function cancel() {
  emit("canceled");
}

function save() {
  let variableToCreate = {
    reference: {
      parent: parent.value,
      code: code.value
    },
    label: label.value,
    type: type.value,
    nature: nature.value,
    datasetCode: dataset.value ? dataset.value.code : null
  }
  emit("saved", variableToCreate)
}

function datasetItemTitle(dataset) {
  return `${dataset.code} - ${dataset.label}`
}

</script>