<template>
  <v-card-actions class="mb-3">
    <v-row align="center">
      <v-col cols="8">
        <Breadcrumb :reference="$route.params.collectionCode + '/Simulation'"/>
      </v-col>
      <v-col class="d-flex justify-space-between align-center">
        <v-select v-model="selectedCase" :items="cases" clearable variant="outlined" item-title="code" dense
                  hide-details bg-color="white"
                  :label="t('catalog.simulation.case.load')" class="mr-2" @update:modelValue="loadCase"
                  return-object></v-select>
        <v-btn icon="mdi-cog" @click="editCases = !editCases"></v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
  <SimulationForm v-if="collection.code" :collection-code="collection.code" @cleared="clearInputs"
                  @saved="onCaseSaved"/>
  <v-dialog v-model="editCases" persistent>
    <v-card width="720px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ t('catalog.simulation.case.edit') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-table>
          <thead>
          <tr>
            <th id="code" class="text-left">{{ t('common.code') }}</th>
            <th id="actions"/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in cases" :key="item.code">
            <td>{{ item.code }}</td>
            <td class="d-flex flex-row align-center justify-end">
              <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2"
                     @click="deleteCase(item)"></v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-row class="mr-3">
          <v-spacer></v-spacer>
          <v-btn @click="editCases = !editCases" color="action">{{ t('common.ok') }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <Loading :loading="loading" :text="t('catalog.simulation.loading-form')"/>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import SimulationForm from "@/components/catalog/SimulationForm.vue";
import Breadcrumb from "@/components/catalog/CatalogBreadcrumb.vue";
import Loading from "@/components/common/Loading.vue";
import {onMounted, onUnmounted, ref} from "vue";
import {useCatalogApi} from "@/composables/useCatalogApi";
import {useRoute} from "vue-router";
import {useSimulationStore} from "@/store/simulation";
import {useI18n} from "vue-i18n";

let collection = ref({})
let cases = ref([])
let selectedCase = ref(null)
let editCases = ref(false)
let loading = ref(true)

let catalogApi = useCatalogApi()
let route = useRoute()
let simulationStore = useSimulationStore()

onMounted(() => {
  catalogApi.$collection.exportCollection(route.params.collectionCode)
    .then(result => {
      simulationStore.setCurrentDefinitions(result.variables)
      collection.value = result;
      catalogApi.$simulation.listCases(collection.value.code)
        .then(reponseCases => cases.value = reponseCases)
    })
    .finally(() => loading.value = false)
})

onUnmounted(() => clearInputs())

function clearInputs() {
  selectedCase.value = null
  simulationStore.setSelectedSimulationCase(null)
}

function loadCase(selectedCase) {
  if (selectedCase) {
    catalogApi.$simulation.getCase(collection.value.code, selectedCase.code)
      .then(caseData => {
        simulationStore.setSelectedSimulationCase(caseData)
      })
  } else {
    clearInputs()
  }
}

function onCaseSaved(savedCase) {
  if (cases.value.findIndex(c => c.code === savedCase) === -1) {
    cases.value.push({
      code: savedCase
    })
    selectedCase.value = savedCase
  }
}

let {t} = useI18n()

function deleteCase(simulationCase) {
  catalogApi.$simulation.deleteCase(simulationCase)
    .then(result => {
      cases.value = cases.value.filter(c => c.code !== simulationCase.code)
      toast.warning(t('catalog.simulation.case.deleted', {code: simulationCase.code}))
    })
}
</script>