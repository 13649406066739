<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-if="property.creation">
          <span v-if="property.creation"
                v-html="t('referential.classifier.properties.new-title', {classifier: classifierCode})"/>
          <span v-else v-html="t('referential.classifier.properties.edit-title', {code: property.code})"/>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="property.code" :edit="property.creation" class="mb-6" :allow-start-by-number="true"/>
      <v-text-field v-model="property.label" :label="t('common.label')" variant="outlined" bg-color="white"/>
      <v-select v-model="property.type" :items="types" variant="outlined" bg-color="white" return-object=""
                :label="t('referential.classifier.type.label')" :item-title="(e) => t('referential.classifier.type.' + e.toLowerCase())" />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import YesNoField from "@/components/common/YesNoField.vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
  property: {},
  classifierCode: String
})

let {t} = useI18n()
let type = ref(null)
let types = ['STRING', 'NUMBER', 'BOOLEAN', 'DATE']

const emit = defineEmits(['canceled', 'saved'])

function cancel() {
  emit("canceled");
}

function save() {
  emit("saved", props.property)
}
</script>