<template>
    <CreateComponent type="classifier" :show-import-mode="false"
                     @canceled="$emit('canceled')" @saved="save()" @imported="imported => importJson(imported)">
        <template v-slot:form>
            <v-card-text>
                <CodeTextField v-model="classifier.code" :edit="true" class="mb-6"/>
                <v-text-field v-model="classifier.label" :label="t('common.label')" variant="outlined"
                              bg-color="white"/>
                <v-select v-model="selectedDataset" :items="datasets" :item-title="d => `${d.code} - ${d.label}`"
                          :label="t('referential.dataset.label', 1)" return-object
                          variant="outlined" :bg-color="dataset ? '' : 'white'" :readonly="!!dataset"></v-select>
                <v-select v-model="classifier.type" :items="types" :label="t('referential.classifier.type.label')"
                          variant="outlined" bg-color="white" return-object
                          :item-title="i => t('referential.classifier.type.' + i.toLowerCase())"/>
            </v-card-text>
        </template>
    </CreateComponent>
</template>
<script setup>
import CodeTextField from "@/components/common/CodeTextField.vue";
import CreateComponent from "@/components/common/CreateComponent.vue";
import {onMounted, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useI18n} from "vue-i18n";

const props = defineProps(["dataset"])
const emit = defineEmits(['canceled', 'saved', 'imported'])

let {t} = useI18n()
let referentialApi = useReferentialApi()

onMounted(() => {
    referentialApi.$dataset.list()
      .then(result => datasets.value = result)
})

const classifier = ref({})
const datasets = ref([])
const selectedDataset = ref(props.dataset)
const types = ['COMPOSITE', 'STRING', 'NUMBER', 'BOOLEAN', 'DATE']

const save = () => {
    classifier.value.datasetCode = selectedDataset.value ? selectedDataset.value.code : null;
    emit("saved", classifier.value)
}
const importJson = (importedClassifier) => {
    delete importedClassifier.mode
    emit("imported", importedClassifier)
}
</script>