  <template>
  <div v-if="classifier">
    <Loading :loading="loading" :text="t('referential.classifier.loading-classifier', {code: classifier.code})"/>
    <v-card-actions class="align-center">
      <ReferentialBreadcrumb :classifier="classifier.code" :dataset="classifier.datasetCode"/>
      <v-spacer/>
      <ButtonWithTooltip icon="mdi-play" color="primary" :edit="edit" :label="t('common.simulate')"
                         :tooltip-text="t('referential.classifier.simulate', {code: classifier.code})"
                         variant="contained-text" size="large" density="comfortable"
                         @clicked="simulate = !simulate"/>
      <ButtonWithTooltip icon="mdi-download" color="action" :edit="edit" @clicked="openJson = true"
                         variant="contained-text" size="large" density="comfortable"
                         :tooltip-text="t('referential.classifier.export.label')"
                         :label="t('common.export.label')"/>
    </v-card-actions>
    <v-card class="mt-3" density="compact">
      <v-card-actions>
        <v-spacer/>
        <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                           :tooltip-text="t('referential.classifier.edit')"/>
        <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteClassifier"
                           :tooltip-text="t('referential.classifier.delete')"/>
      </v-card-actions>

      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="4">
            <CodeTextField v-model="classifier.code" :edit="false"/>
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="classifier.label" :label="t('common.label')" :readonly="!edit"
                          :bg-color="edit ? 'white' : ''" :variant="edit ? 'outlined' : 'plain'"
                          hide-details/>
          </v-col>
          <v-col cols="2">
            <v-chip color="blue" label>
                            <span style="font-family: monospace, serif">
                                {{ t('referential.classifier.type.' + classifier.type.toLowerCase()) }}
                            </span>
            </v-chip>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-4">
          <v-col cols="11">
            <v-card>
              <v-toolbar color="primary">
                <v-toolbar-title>{{ t('common.key', 2) }}</v-toolbar-title>
              </v-toolbar>
              <v-row class="ma-4">
                <v-col v-if="dataset">
                  <v-label class="text-subtitle-1 mb-8">
                    {{ t('referential.classifier.dataset-properties') }}
                  </v-label>
                  <draggable class="list-group" :list="datasetProperties" group="people"
                             itemKey="code"
                             draggable="false">
                    <template #item="{ element, index }">
                      <v-row class="mb-4">
                        <v-col class="border bg-white d-flex flex-row justify-space-between">
                          <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
                            {{ element }} </strong>
                          <v-icon v-if="edit" color="primary" @click="addToKeys(index, element)">
                            mdi-plus
                          </v-icon>
                        </v-col>
                      </v-row>
                    </template>
                  </draggable>
                </v-col>
                <v-divider vertical style="height: auto" class="mx-4"></v-divider>
                <v-col v-if="dataset">
                  <v-label class="text-subtitle-1 mb-8">
                    {{ t('referential.classifier.used-properties') }}
                  </v-label>
                  <draggable class="list-group" :list="classifierKeys" group="people" itemKey="code"
                             handle=".handle">
                    <template #item="{ element, index }">
                      <v-row class="mb-4" align="center">
                        <v-col cols="2"
                               class="bg-primary d-flex flex-row justify-center align-center">
                          <v-icon v-if="edit" class="handle mr-2" size="small">
                            mdi-drag-horizontal-variant
                          </v-icon>
                          <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
                            {{ index + 1 }}
                          </strong>
                        </v-col>
                        <v-col class="border bg-white d-flex flex-row justify-space-between">
                          <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
                            {{ element }}
                          </strong>
                          <v-icon v-if="edit" color="error"
                                  @click="removeFromKeys(index, element)">mdi-minus
                          </v-icon>
                        </v-col>
                      </v-row>
                    </template>
                  </draggable>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn v-if="edit" color="error" @click="cancelEditing">{{ t('common.cancel') }}</v-btn>
        <v-btn v-if="edit" color="primary" @click="updateClassifier">{{ t('common.save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <ClassifierProperties v-if="classifier.type === 'COMPOSITE'" :classifier="classifier"/>
    <ClassifierRecords :classifier="classifier"/>
  </div>
  <v-dialog v-model="simulate" persistent>
    <ClassifierSimulation :classifier="classifier" @closed="simulate = !simulate"/>
  </v-dialog>
  <v-dialog v-model="openJson" persistent>
    <DisplayJson :code="classifier.code" type="classifier" :data="classifier" @closed="openJson = false"/>
  </v-dialog>
</template>
<script setup>
import {toast} from 'vue3-toastify';
import ReferentialBreadcrumb from "@/components/referential/ReferentialBreadcrumb.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import ClassifierProperties from "@/components/referential/ClassifierProperties.vue";
import draggable from "vuedraggable";
import ClassifierRecords from "@/components/referential/ClassifierRecords.vue";
import ClassifierSimulation from "@/components/referential/ClassifierSimulation.vue";
import Loading from "@/components/common/Loading.vue";
import DisplayJson from "@/components/common/DisplayJson.vue";
import {onMounted, ref} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";
import {useRoute, useRouter} from "vue-router";
import useConfirmDialog from "@/composables/useConfirmDialog";
import {useI18n} from "vue-i18n";

let classifier = ref(null)
let dataset = ref(null)
let edit = ref(false)
let simulate = ref(false)
let datasetProperties = ref([])
let classifierKeys = ref([])
let loading = ref(true)
let openJson = ref(false)

let referentialApi = useReferentialApi()
let route = useRoute()
let router = useRouter()

onMounted(() => referentialApi.$classifier.find(route.params.classifierCode)
  .then(result => {
    classifier.value = result
    if (classifier.value.keys) {
      classifierKeys.value = classifier.value.keys.sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1).map(k => k.propertyCode)
    }
    referentialApi.$dataset.find(classifier.value.datasetCode)
      .then(result => {
        dataset.value = result
        let tmpDatasetProperties = ['CODE']
        tmpDatasetProperties = tmpDatasetProperties.concat(dataset.value.properties.map(p => p.code))
        tmpDatasetProperties
          .filter(p => !classifierKeys.value.includes(p))
          .forEach(p => datasetProperties.value.push(p))
      })
  })
  .finally(() => loading.value = false)
)

let {t} = useI18n()

function deleteClassifier() {
  useConfirmDialog(
    {
      title: t('referential.classifier.delete-msg', {code: classifier.value.code}),
      message: t('referential.classifier.confirm-delete-msg', {code: classifier.value.code}),
      confirmation: classifier.value.code
    },
    () => referentialApi.$classifier.delete(classifier.value.code)
      .then(deleteOK => {
        if (deleteOK) {
          toast.warning(t('referential.classifier.deleted', {code: classifier.value.code}))
          router.push({name: 'referential'});
        } else {
          toast.error(t('referential.classifier.error-on-delete', {code: classifier.value.code}))
        }
      })
  )
}

function cancelEditing() {
  edit.value = !edit.value;
}

function updateClassifier() {
  classifier.value.keys = classifierKeys.value.map((value, index) => ({propertyCode: value, orderIndex: index}))
  referentialApi.$classifier.update(classifier.value)
    .then(() => toast.success(t('referential.classifier.updated', {code: classifier.value.code})))
    .finally(() => edit.value = false)
}

function addToKeys(index, property) {
  datasetProperties.value.splice(index, 1)
  classifierKeys.value.push(property)
}

function removeFromKeys(index, property) {
  classifierKeys.value.splice(index, 1)
  datasetProperties.value.push(property)
}
</script>